@use "@scss/global.scss" as *;
@use "@scss/variables.scss" as *;

@use "@scss/responsive.scss" as *;

.auth-layout {
  min-height: 100vh;

  .content {
    padding: 20px 20px $footer-height;
    min-height: 100vh;
    height: max-content;
    @include flex-center();
  }

  footer.auth-footer {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 0px $gutter-x;
    padding-top: $size-1;
    height: $footer-height;
    backdrop-filter: blur(40.7742px);
    filter: blur(0);
    background: rgba(246, 246, 246, 0.02);
  }

  @media (max-height: 900px) {
    height: unset;
  }

  @include mq-max(1000px) {
    footer.auth-footer {
      background: transparent;
      position: relative;
    }
  }
}
