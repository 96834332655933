@use "./src/scss/global.scss" as *;

.accordion-tab-content {
  overflow-y: hidden;
  width: 100%;
  transition-property: height;
  transition: 0.5s cubic-bezier(0.69, -0.23, 0.31, 1.23);

  .answer {
    max-width: 96%;
  }

  .link-hoverable {
    transition: color 0.2s ease-in-out;
    text-underline-position: under;
    &:hover {
      color: $black-0;
      opacity: 1;
    }
  }
}
