@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}

.lineThrough {
  text-decoration: line-through;
}

.break-word {
  word-break: break-word;
}

.ellipsis {
  word-wrap: break-word;
  @include ellipsis-after-lines(1);
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.required {
  &.text:after {
    content: "*";
    color: $danger;
  }
}

.none-events {
  pointer-events: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.roundness-1 {
  border-radius: $roundness-1;
}

.roundness-2 {
  border-radius: $roundness-2;
}

.roundness-full {
  border-radius: 100%;
}

.col {
  flex-direction: column !important;
}
.row {
  flex-direction: row !important;
}
.grid {
  flex-wrap: wrap !important;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.light {
  font-weight: $light !important;
}
.regular {
  font-weight: $regular !important;
}
.medium {
  font-weight: $medium !important;
}
.semiBold {
  font-weight: $semiBold !important;
}
.bold {
  font-weight: $bold !important;
}

.shadow--1 {
  &:hover {
    box-shadow: $bs--1;
  }
}
.shadow-0 {
  &:hover {
    box-shadow: $bs-0;
  }
}
.shadow-1 {
  &:hover {
    box-shadow: $bs-1;
  }
}
.shadow-2 {
  &:hover {
    box-shadow: $bs-2;
  }
}
.shadow-3 {
  &:hover {
    box-shadow: $bs-3;
  }
}
.shadow-4 {
  &:hover {
    box-shadow: $bs-4;
  }
}
.shadow-5 {
  &:hover {
    box-shadow: $bs-5;
  }
}
.shadow-6 {
  &:hover {
    box-shadow: $bs-6;
  }
}
.shadow-7 {
  &:hover {
    box-shadow: $bs-7;
  }
}

.border-primary {
  &:hover {
    @include border($primary);
  }
}

.border-success {
  &:hover {
    @include border($success);
  }
}

.loader {
  animation: spin 2s linear infinite;
}

.op-0 {
  opacity: 0;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}
.op-10 {
  opacity: 1;
}

.text-columns-2 {
  column-count: 2;
  word-break: break-word;
}

// @each $bp-key, $bp-val in $breakpoints {
//   @each $wv-key, $wv-val in $width-variants {
//     @include mq-min($bp-val) {
//       .w-#{$bp-key}-#{$wv-key} {
//         width: $wv-val;
//       }
//       .h-#{$bp-key}-#{$wv-key} {
//         height: $wv-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $pv-key, $pv-val in $position-variants {
//     @include mq-min($bp-val) {
//       .flex-#{$bp-key}-#{$pv-key} {
//         align-items: $pv-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $tr-key, $tr-val in $text-responsive {
//     @include mq-min($bp-val) {
//       .t-#{$bp-key}-#{$tr-key} *,
//       .t-#{$bp-key}-#{$tr-key} {
//         text-align: $tr-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $displ-key, $displ-val in $display-variants {
//     @include mq-min($bp-val) {
//       .d-#{$bp-key}-#{$displ-key} {
//         display: $displ-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $grid-key, $grid-val in $grid-values {
//     @include mq-min($bp-val) {
//       .#{$bp-key}-#{$grid-key} {
//         width: $grid-val;
//       }

//       .off-#{$bp-key}-#{$grid-key} {
//         margin-left: $grid-val;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @each $mp-key, $mp-val in $margins-paddings {
//       @include mq-min($bp-val) {
//         .#{$mp-key}-#{$bp-key}-#{$sv-key} {
//           #{$mp-val}: $sv-val;
//         }
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @include mq-min($bp-val) {
//       .m-#{$bp-key}-a {
//         margin: auto;
//       }
//     }
//   }
// }

// @each $bp-key, $bp-val in $breakpoints {
//   @each $sv-key, $sv-val in $size-variants {
//     @include mq-min($bp-val) {
//       .rg-#{$bp-key}-#{$sv-key} {
//         row-gap: $sv-val;
//       }

//       .cg-#{$bp-key}-#{$sv-key} {
//         column-gap: $sv-val;
//       }
//     }
//   }
// }
// @each $bp-key, $bp-val in $breakpoints {
//   @each $ov-key in $order-variants {
//     @include mq-min($bp-val) {
//       .or-#{$bp-key}-#{$ov-key} {
//         order: $ov-key;
//       }
//     }
//   }
// }
