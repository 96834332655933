@use "@scss/global.scss" as *;

.input-wrapper {
  .input-itself {
    @include h3;
    border-radius: 10px;
    box-shadow: $bs--1;
    border-radius: $roundness-2;
    color: $black-1;

    &::placeholder {
      font-size: $fs-3 !important;
      line-height: $lh-2 !important;
      letter-spacing: $ls-1 !important;
      font-weight: $medium !important;
    }
  }
}

textarea {
  @include p2;
  border-radius: 10px;
  box-shadow: $bs--1;
  border-radius: $roundness-2;
  color: $black-1;

  &::placeholder {
    font-size: $fs-3 !important;
    line-height: $lh-2 !important;
    letter-spacing: $ls-1 !important;
    font-weight: $regular !important;
  }

  &:not([value=""]):not(:focus) {
    background-color: $white-1;
  }
}
