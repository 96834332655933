@use "@scss/global.scss" as *;

@mixin hover {
  cursor: pointer;

  &.primary-link {
    background-color: $primary-hover;
    box-shadow: $bs-hover;
  }
  &.secondary-link {
    background-color: $black-1;
    box-shadow: $bs-40;
  }
  &.trietary-link {
    background-color: $primary-hover;
    border: 1px solid transparent;
  }

  @include mq-max($lg) {
    &.primary-btn,
    &.secondary-btn {
      box-shadow: $bs-active-mobile;
    }
  }
}
%hover {
  @include hover;
}

@mixin active {
  background-color: $primary-pressed !important;
  box-shadow: $bs-hover;
}

%active {
  @include active;
}

.g2n-link {
  min-width: $size-dx * 30;
  width: max-content;
  color: $black-0;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out,
    color 0.3s ease-in-out;
  height: 50px;
  @include flex-center;

  > * {
    width: 100%;
    max-height: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-size {
    width: 100%;
  }

  &.primary-link {
    font-size: $fs-5;
    padding: 0px $size-4;
    line-height: 29.5px;
    font-weight: $medium;
    letter-spacing: $ls-4;
    background-color: $primary;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }

  &.secondary-link {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    color: $black-4;
    background-color: $white-2;
    border: 1px solid $white-3;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }

  &.trietary-link {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    height: 42px;
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;
    &:active {
      @extend %active;
    }
    &:hover {
      background-color: $white-0;
      color: $black-0;
    }
  }

  &:hover,
  &.hover {
    @extend %hover;
  }

  &.link {
    font-size: $fs-6;
    font-weight: $medium;
    background-color: transparent;
    height: max-content;
    min-width: max-content;

    &:hover {
      color: $primary;
    }
  }

  &.disabled {
    opacity: 0.24;
    pointer-events: none;
  }

  @media (max-width: $lg) {
    background-color: $primary-pressed;
    border: 1px solid transparent;
    width: 100%;
  }
}
