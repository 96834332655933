@use "./src/scss/global.scss" as *;

.help-explainer {
  &-video-box {
    width: 100%;
    height: 624px;
    padding: 24px;
    background-color: $white-0;
    box-shadow: $bs-2;
    border-radius: 10px;

    iframe {
      border-radius: 4px;
      border: unset;
      outline: unset;
    }
  }
}
