@use "@scss/global.scss" as *;

.social-buttons {
  .social-button {
    // box-sizing: content-box;
    // padding: 4px;
    width: 30px;
    height: 30px;
  }
}
