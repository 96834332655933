@use "./src/scss/global.scss" as *;

.reset-password {
  width: max-content;
  margin: auto;

  .password-label {
    width: 445px;
  }

  .password-input {
    input::placeholder {
      font-size: 14px !important;
    }
  }

  @include mq-max($md) {
    .error-title {
      font-size: 28px !important;
    }
  }
}
