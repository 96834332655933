@use "@scss/global.scss" as *;

.rt-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: max-content;
  box-sizing: border-box;
  width: calc(100% + $gutter-x);
  margin-left: calc($gutter-x * -0.5) !important;
  margin-right: calc($gutter-x * -0.5) !important;

  > .rt-col {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-right: calc($gutter-x * 0.5);
    padding-left: calc($gutter-x * 0.5);
  }
}
