@use "@scss/global.scss" as *;

@mixin hover {
  cursor: pointer;

  &.primary-btn {
    background-color: $primary-hover;
    box-shadow: $bs-hover;
  }
  &.secondary-btn {
    box-shadow: $bs-40;
  }
  &.trietary-btn {
    background-color: $primary-hover;
    border: 1px solid transparent;
  }

  @include mq-max($lg) {
    &.primary-btn,
    &.secondary-btn {
      box-shadow: $bs-active-mobile;
    }
  }
}

%hover {
  @include hover;
}

@mixin active {
  background-color: $primary-pressed !important;
  box-shadow: $bs-hover;
}

%active {
  @include active;
}

.g2n-button {
  // min-width: $size-dx * 30;
  width: max-content;
  color: $black-0;
  transition: all 0.3s ease-in-out;
  height: 50px;
  border-radius: 2px;

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-size {
    width: 100%;
  }

  &.primary-btn {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    font-weight: $medium;
    letter-spacing: $ls-4;

    background-color: $primary;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }
  &.secondary-btn {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    color: $black-1;
    background-color: $white-2;
    border: 1px solid $white-3;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }
  &.trietary-btn {
    font-size: $fs-7;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    height: 42px;
    border: 1px solid $black-0;
    background-color: transparent;
    &:active {
      @extend %active;
    }
  }

  &:hover,
  &.hover {
    @extend %hover;
  }

  &.link-btn {
    font-weight: $medium;
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.24;
    pointer-events: none;
  }

  &.free-width {
    min-width: unset;
    width: 100%;
  }

  @media (max-width: $lg) {
    background-color: $primary-pressed;
    border: 1px solid transparent;
    width: 100%;
  }
}
