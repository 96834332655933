@use "@scss/global.scss" as *;

.modal {
  height: max-content;
  width: max-content;
  max-width: 100%;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $white-1;
  border-radius: 10px;
  padding: 80px;

  &-close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
