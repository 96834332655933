@use "@scss/global.scss" as *;

.floater-inset {
  .g2n-box {
    padding-bottom: 30px;
  }
  .title {
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: $black-6;
    opacity: 0.5;
  }
}

.input-text-style {
  @include h3;

  &::placeholder {
    font-size: $fs-3 !important;
    line-height: $lh-2 !important;
    letter-spacing: $ls-1 !important;
    font-weight: $medium !important;
  }

  &:not([value=""]):not(:focus) {
    background-color: $white-1;
  }
}
