@use "@scss/global.scss" as *;

.app-layout {
  position: relative;
  height: max-content;
  min-height: 100vh;
  // overflow-x: hidden;

  header.app-navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 0px $gutter-x;
    height: $navbar-height;
    z-index: 10;
    // backdrop-filter: blur(40.7742px);
    // background: $white-1;
    // box-shadow: $bs-4;
  }

  main.content {
    padding: $navbar-height 20px $footer-height;
    // min-height: 100vh;
    // height: max-content;
    height: max-content;
    min-height: 100vh;
    // min-height: 100%;
    // width: 100%;
    display: flex;
    // overflow-x: hidden;
    > * {
      flex: 1;
    }
  }

  footer.app-footer {
    position: fixed;
    height: $footer-height;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0px $gutter-x;
    padding-top: $size-1;
    backdrop-filter: blur(40.7742px);
    filter: blur(0);
    background: rgba(246, 246, 246, 0.02);
    z-index: 2;
  }

  @media (max-height: 900px) {
    height: unset;
  }

  @include mq-max(1000px) {
    footer.app-footer {
      background: transparent;
      position: relative;
    }
  }
}
