@use "./src/scss/global.scss" as *;

.accordion-tab {
  width: 100%;
  height: max-content;
  position: relative;
  padding-bottom: 24px;
  scroll-margin-top: 90px;

  &-button {
    width: 100%;
    cursor: pointer;
    padding-bottom: 19px;
  }

  &-divider {
    opacity: 0.2;
    height: 1px;
    background-color: $black-1;
    transition: background-color, opacity 0.2s ease-in-out;

    &-hover {
      background-color: $primary;
      opacity: 1;
    }

    &-active {
      background-color: $black-1;
      opacity: 1;
    }
  }
}
