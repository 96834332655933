@use "@scss/global.scss" as *;

.nav-links {
  .nav-link {
    padding: 13.5px 16px;
    text-transform: uppercase;
    border-radius: $roundness-2;
    transition: background 0.15s ease-in-out;

    &:hover:not(.active) {
      background: $white-2;
    }

    &.active {
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 11px;
      text-decoration-thickness: 4px;
      text-decoration-color: $primary;
      font-weight: $bold;
    }
  }
}
