@use "@scss/global.scss" as *;

.not-found {
  height: 100vh;
  @include flex-center();

  .nav-button {
  }

  .description {
    max-width: 600px;
  }
}
