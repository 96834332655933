@use "@scss/global.scss" as *;

.mobile-guard {
  padding: 0px 20px;
  height: max-content;
  min-height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10000;
  background-color: $white-0;

  .description {
    max-width: 600px;
  }
}
