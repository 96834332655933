@use "./variables.scss" as *;

@mixin group {
  display: flex;
  flex-direction: row;
}

@mixin stack {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row($direction) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: $direction;
}

@mixin flex-column($v-direction, $h-direction: center) {
  display: flex;
  flex-direction: column;
  align-items: $v-direction;
  justify-content: $h-direction;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@mixin ellipsis-after-lines($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-scaffold($fs, $fw, $color, $lh, $ls) {
  font-size: $fs !important;
  font-weight: $fw !important;
  color: $color !important;
  line-height: $lh !important;
  letter-spacing: $ls !important;
}

@mixin absolute-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin after($h: 100%, $w: 100%) {
  position: absolute;
  content: "";
  height: $h;
  width: $w;
}

@mixin hero-img($url) {
  background-image: url($url);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin border($border-color) {
  border: 1px solid $border-color;
}

@mixin mq-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin h0 {
  @include h1;
  font-size: 96px;
}

@mixin h1 {
  font-size: $fs-1;
  line-height: $lh-2;
  letter-spacing: $ls-1;
  font-weight: $light;
}

@mixin h2 {
  font-size: $fs-2;
  line-height: $lh-2;
  letter-spacing: $ls-1;
  font-weight: $light;
}

@mixin h2-5 {
  font-size: $fs-2-5;
  line-height: $lh-2;
  letter-spacing: $ls-2;
  font-weight: $medium;
}

@mixin h3 {
  font-size: $fs-3;
  line-height: $lh-2;
  letter-spacing: $ls-1;
  font-weight: $medium;
}

@mixin h4 {
  font-size: $fs-4;
  line-height: $lh-2;
  letter-spacing: $ls-1;
  font-weight: $medium;
}

@mixin h5 {
  @include p2;
}

@mixin p1 {
  font-size: $fs-5;
  line-height: $lh-3;
  letter-spacing: $ls-4;
  font-weight: $regular;
  color: $black-1;
}

@mixin p2 {
  font-size: $fs-6;
  line-height: $lh-3;
  letter-spacing: $ls-4;
  font-weight: $regular;
}

@mixin p3 {
  font-size: $fs-7;
  line-height: $lh-4;
  letter-spacing: $ls-2;
  font-weight: $regular;
  color: $black-1;
}

@mixin p4 {
  font-size: $fs-7;
  line-height: $lh-4;
  letter-spacing: $ls-2;
  font-weight: $semiBold;
  color: $black-0;
}
