@use "@scss/global.scss" as *;

.modal-close-button {
  padding: 20px;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: $primary-hover;
    }
  }

  &:disabled {
    pointer-events: none;
  }
}
