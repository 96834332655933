@use "@scss/global.scss" as *;

.sign-out-btn {
  height: max-content;
  padding: 13.5px 16px;
  border-radius: $roundness-2;
  width: 100%;
  color: $black-0;
  @include p3;
  min-width: 160px;

  &:hover {
    background-color: $white-1;
  }
}

.active-link {
  position: relative;
  border-radius: 100%;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $primary;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    z-index: -1;

    border-radius: inherit;
  }
}
