@use "@scss/global.scss" as *;

.auth-logging {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $white-1;
}

.logo-animation {
  animation: MoveUpDown 2.5s ease-in-out infinite;
  aspect-ratio: 1/1;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(25px);
  }
}
