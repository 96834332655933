@use "@scss/global.scss" as *;

.footer {
  &-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 8px;
  }

  &-content-pdfs {
    display: flex;
    gap: 5px;

    .pdf-separator {
      color: $black-4;
      font-size: 12px;
    }
  }

  &-content-socials {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  @include mq-max(1000px) {
    &-content {
      flex-flow: column nowrap;
      gap: 16px;
    }

    &-content-pdfs {
      flex-flow: column;
      align-items: center;

      .pdf-separator {
        display: none;
      }
    }

    &-content-socials {
      flex-flow: column nowrap;
      gap: 16px;
    }
  }
}
