@use "@scss/global.scss" as *;
@use "@scss/variables.scss" as *;

.group {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;

  &.full-size {
    height: 100%;
    width: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.grid {
    box-sizing: border-box;
    width: calc(100% + 32px);
    margin-left: calc($gutter-x * -0.5) !important;
    margin-right: calc($gutter-x * -0.5) !important;

    > .stack {
      box-sizing: border-box;
      align-items: flex-start;
      padding-right: calc($gutter-x * 0.5);
      padding-left: calc($gutter-x * 0.5);
    }
  }

  // img,
  svg {
    max-height: 100%;
  }
}
