@use "@scss/global.scss" as *;

.navbar {
  height: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: blur(40.7742px);
  filter: blur(0);
  background: rgba(246, 246, 246, 0.02);
  width: 100%;

  .clickable-logo {
    height: 24px;
  }

  .order-tags {
    @include flex-center();
    column-gap: 8px;
    padding: 8px 16px;
    background-color: $white-0;
    border-radius: $roundness-2;
    box-shadow: $bs-1;

    &.active {
      border: 2px solid $primary;
    }
  }
}
