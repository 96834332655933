@use "@scss/variables.scss" as *;
@use "./mixins.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  color: $black-0;
  min-height: 100vh;
  height: max-content;
  // scroll-behavior: smooth;
  background-color: $white-1;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: "Poppins", sans-serif !important;
}

body {
  transition: background 0.5s ease-in-out;
}

html,
body,
#root,
section {
  height: max-content;
  min-height: 100vh;
  // height: max-content;
  width: 100%;
}

#root {
  > * {
    width: 100%;
  }
}

section {
  padding: 0px calc($gutter-x * 0.5);
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

input,
textarea {
  border: none;
  outline: none;
}

hr {
  border: none;
}

#overlay {
  position: absolute;
  height: 100vh;
  // width: 100vw;
  left: 0px;
  top: 0px;
  z-index: 1000;

  .alert {
    z-index: 6001;
  }
  .ds-toast,
  .ds-toast-v3 {
    z-index: 5001;
  }
  .message {
    z-index: 4001;
  }
  .modal {
    z-index: 3001;
  }
  .dialog {
    z-index: 2001;
  }
  .drawer {
    z-index: 1001;
  }
}
