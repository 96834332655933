@use "@scss/variables.scss" as *;

%container {
  width: 1140px !important;
  max-width: 100% !important;
  margin: auto !important;
  height: max-content !important;
}

.container {
  @extend %container;
}

%container-1 {
  width: 100%;
  margin: 0 auto;
  height: max-content;
}

.rt-container-xl {
  @extend %container-1;
  max-width: 1140px;
}
.rt-container-xxl {
  @extend %container-1;
  max-width: 1440px;
}

.rt-container-xl,
.rt-container-xxl {
  &.full-height {
    height: 100%;
    *:not(h1, h2, h3, h4, h5, h6, p, img, header) {
      height: 100%;
    }
  }
}

@media (min-width: 0px) {
  .xs-0 {
    width: 0;
  }
  .off-xs-0 {
    margin-left: 0;
  }
  .xs-1 {
    width: 8.3333333333%;
  }
  .off-xs-1 {
    margin-left: 8.3333333333%;
  }
  .xs-2 {
    width: 16.6666666667%;
  }
  .off-xs-2 {
    margin-left: 16.6666666667%;
  }
  .xs-3 {
    width: 25%;
  }
  .off-xs-3 {
    margin-left: 25%;
  }
  .xs-4 {
    width: 33.3333333333%;
  }
  .off-xs-4 {
    margin-left: 33.3333333333%;
  }
  .xs-5 {
    width: 41.6666666667%;
  }
  .off-xs-5 {
    margin-left: 41.6666666667%;
  }
  .xs-6 {
    width: 50%;
  }
  .off-xs-6 {
    margin-left: 50%;
  }
  .xs-7 {
    width: 58.3333333333%;
  }
  .off-xs-7 {
    margin-left: 58.3333333333%;
  }
  .xs-8 {
    width: 66.6666666667%;
  }
  .off-xs-8 {
    margin-left: 66.6666666667%;
  }
  .xs-9 {
    width: 75%;
  }
  .off-xs-9 {
    margin-left: 75%;
  }
  .xs-10 {
    width: 83.3333333333%;
  }
  .off-xs-10 {
    margin-left: 83.3333333333%;
  }
  .xs-11 {
    width: 83.3333333333%;
  }
  .off-xs-11 {
    margin-left: 83.3333333333%;
  }
  .xs-12 {
    width: 100%;
  }
  .off-xs-12 {
    margin-left: 100%;
  }
}
@media (min-width: 576px) {
  .sm-0 {
    width: 0;
  }
  .off-sm-0 {
    margin-left: 0;
  }
  .sm-1 {
    width: 8.3333333333%;
  }
  .off-sm-1 {
    margin-left: 8.3333333333%;
  }
  .sm-2 {
    width: 16.6666666667%;
  }
  .off-sm-2 {
    margin-left: 16.6666666667%;
  }
  .sm-3 {
    width: 25%;
  }
  .off-sm-3 {
    margin-left: 25%;
  }
  .sm-4 {
    width: 33.3333333333%;
  }
  .off-sm-4 {
    margin-left: 33.3333333333%;
  }
  .sm-5 {
    width: 41.6666666667%;
  }
  .off-sm-5 {
    margin-left: 41.6666666667%;
  }
  .sm-6 {
    width: 50%;
  }
  .off-sm-6 {
    margin-left: 50%;
  }
  .sm-7 {
    width: 58.3333333333%;
  }
  .off-sm-7 {
    margin-left: 58.3333333333%;
  }
  .sm-8 {
    width: 66.6666666667%;
  }
  .off-sm-8 {
    margin-left: 66.6666666667%;
  }
  .sm-9 {
    width: 75%;
  }
  .off-sm-9 {
    margin-left: 75%;
  }
  .sm-10 {
    width: 83.3333333333%;
  }
  .off-sm-10 {
    margin-left: 83.3333333333%;
  }
  .sm-11 {
    width: 83.3333333333%;
  }
  .off-sm-11 {
    margin-left: 83.3333333333%;
  }
  .sm-12 {
    width: 100%;
  }
  .off-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .md-0 {
    width: 0;
  }
  .off-md-0 {
    margin-left: 0;
  }
  .md-1 {
    width: 8.3333333333%;
  }
  .off-md-1 {
    margin-left: 8.3333333333%;
  }
  .md-2 {
    width: 16.6666666667%;
  }
  .off-md-2 {
    margin-left: 16.6666666667%;
  }
  .md-3 {
    width: 25%;
  }
  .off-md-3 {
    margin-left: 25%;
  }
  .md-4 {
    width: 33.3333333333%;
  }
  .off-md-4 {
    margin-left: 33.3333333333%;
  }
  .md-5 {
    width: 41.6666666667%;
  }
  .off-md-5 {
    margin-left: 41.6666666667%;
  }
  .md-6 {
    width: 50%;
  }
  .off-md-6 {
    margin-left: 50%;
  }
  .md-7 {
    width: 58.3333333333%;
  }
  .off-md-7 {
    margin-left: 58.3333333333%;
  }
  .md-8 {
    width: 66.6666666667%;
  }
  .off-md-8 {
    margin-left: 66.6666666667%;
  }
  .md-9 {
    width: 75%;
  }
  .off-md-9 {
    margin-left: 75%;
  }
  .md-10 {
    width: 83.3333333333%;
  }
  .off-md-10 {
    margin-left: 83.3333333333%;
  }
  .md-11 {
    width: 83.3333333333%;
  }
  .off-md-11 {
    margin-left: 83.3333333333%;
  }
  .md-12 {
    width: 100%;
  }
  .off-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .lg-0 {
    width: 0;
  }
  .off-lg-0 {
    margin-left: 0;
  }
  .lg-1 {
    width: 8.3333333333%;
  }
  .off-lg-1 {
    margin-left: 8.3333333333%;
  }
  .lg-2 {
    width: 16.6666666667%;
  }
  .off-lg-2 {
    margin-left: 16.6666666667%;
  }
  .lg-3 {
    width: 25%;
  }
  .off-lg-3 {
    margin-left: 25%;
  }
  .lg-4 {
    width: 33.3333333333%;
  }
  .off-lg-4 {
    margin-left: 33.3333333333%;
  }
  .lg-5 {
    width: 41.6666666667%;
  }
  .off-lg-5 {
    margin-left: 41.6666666667%;
  }
  .lg-6 {
    width: 50%;
  }
  .off-lg-6 {
    margin-left: 50%;
  }
  .lg-7 {
    width: 58.3333333333%;
  }
  .off-lg-7 {
    margin-left: 58.3333333333%;
  }
  .lg-8 {
    width: 66.6666666667%;
  }
  .off-lg-8 {
    margin-left: 66.6666666667%;
  }
  .lg-9 {
    width: 75%;
  }
  .off-lg-9 {
    margin-left: 75%;
  }
  .lg-10 {
    width: 83.3333333333%;
  }
  .off-lg-10 {
    margin-left: 83.3333333333%;
  }
  .lg-11 {
    width: 83.3333333333%;
  }
  .off-lg-11 {
    margin-left: 83.3333333333%;
  }
  .lg-12 {
    width: 100%;
  }
  .off-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .xl-0 {
    width: 0;
  }
  .off-xl-0 {
    margin-left: 0;
  }
  .xl-1 {
    width: 8.3333333333%;
  }
  .off-xl-1 {
    margin-left: 8.3333333333%;
  }
  .xl-2 {
    width: 16.6666666667%;
  }
  .off-xl-2 {
    margin-left: 16.6666666667%;
  }
  .xl-3 {
    width: 25%;
  }
  .off-xl-3 {
    margin-left: 25%;
  }
  .xl-4 {
    width: 33.3333333333%;
  }
  .off-xl-4 {
    margin-left: 33.3333333333%;
  }
  .xl-5 {
    width: 41.6666666667%;
  }
  .off-xl-5 {
    margin-left: 41.6666666667%;
  }
  .xl-6 {
    width: 50%;
  }
  .off-xl-6 {
    margin-left: 50%;
  }
  .xl-7 {
    width: 58.3333333333%;
  }
  .off-xl-7 {
    margin-left: 58.3333333333%;
  }
  .xl-8 {
    width: 66.6666666667%;
  }
  .off-xl-8 {
    margin-left: 66.6666666667%;
  }
  .xl-9 {
    width: 75%;
  }
  .off-xl-9 {
    margin-left: 75%;
  }
  .xl-10 {
    width: 83.3333333333%;
  }
  .off-xl-10 {
    margin-left: 83.3333333333%;
  }
  .xl-11 {
    width: 83.3333333333%;
  }
  .off-xl-11 {
    margin-left: 83.3333333333%;
  }
  .xl-12 {
    width: 100%;
  }
  .off-xl-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1400px) {
  .xxl-0 {
    width: 0;
  }
  .off-xxl-0 {
    margin-left: 0;
  }
  .xxl-1 {
    width: 8.3333333333%;
  }
  .off-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .xxl-2 {
    width: 16.6666666667%;
  }
  .off-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .xxl-3 {
    width: 25%;
  }
  .off-xxl-3 {
    margin-left: 25%;
  }
  .xxl-4 {
    width: 33.3333333333%;
  }
  .off-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .xxl-5 {
    width: 41.6666666667%;
  }
  .off-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .xxl-6 {
    width: 50%;
  }
  .off-xxl-6 {
    margin-left: 50%;
  }
  .xxl-7 {
    width: 58.3333333333%;
  }
  .off-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .xxl-8 {
    width: 66.6666666667%;
  }
  .off-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .xxl-9 {
    width: 75%;
  }
  .off-xxl-9 {
    margin-left: 75%;
  }
  .xxl-10 {
    width: 83.3333333333%;
  }
  .off-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .xxl-11 {
    width: 83.3333333333%;
  }
  .off-xxl-11 {
    margin-left: 83.3333333333%;
  }
  .xxl-12 {
    width: 100%;
  }
  .off-xxl-12 {
    margin-left: 100%;
  }
}
