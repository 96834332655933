@use "@scss/global.scss" as *;

.generator {
  // overflow-x: hidden;

  [class$="-view"] {
    width: 100%;
    height: max-content;
  }

  .g2n-box {
    border: 0px;
  }

  .generator-box {
    min-height: 380px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
