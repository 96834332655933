@use "@scss/global.scss" as *;
@use "sass:math";

.ds-dropdown {
  position: relative;
  height: auto;

  .ds-dropdown-button {
    cursor: pointer;
  }

  .ds-dropdown-list {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &.list-roundness {
      border-radius: $roundness-2;
    }
    &.list-x {
      width: max-content;
      right: 0%;
    }
    &.list-y {
      height: max-content;
      top: calc(100% + 8px);
    }
    &.list-bg {
      background-color: $white-0;
    }
    &.list-shadow {
      box-shadow: $bs-6;
    }
    &.list-padding {
      padding: $size-2;
    }

    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
}

.ds-avatar {
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.ds-spinner {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  aspect-ratio: 1/1;
  animation: spin 2s linear infinite;
}

.ds-tooltip-wrapper {
  $bgColor: #464646;
  $dimension: 10px;
  position: relative;
  width: 100%;

  .ds-tooltip {
    position: absolute;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: $bgColor;
    opacity: 0.85;
    font-size: 12px;
    width: 100%;
    color: #fff;
    z-index: 999;

    &-top {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-0.5rem);
    }

    &-right {
      top: 50%;
      left: 100%;
      transform: translateX(0.5rem) translateY(-50%);
    }

    &-bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(0.5rem);
    }

    &-left {
      top: 50%;
      right: 100%;
      transform: translateX(-0.5rem) translateY(-50%);
    }

    .ds-tooltip-children {
    }

    .ds-tooltip-triangle {
      width: $dimension;
      height: $dimension;
      background-color: $bgColor;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  }
}

.ds-tooltip-v2 {
  position: relative;
  width: max-content;
  height: max-content;

  .children {
    width: max-content;
    height: max-content;
    display: flex;
  }

  .tooltip-content {
    $height: 10px;
    $bg-color: $white-0;

    position: absolute;
    left: 50%;
    bottom: calc(50% + $height);
    height: max-content;
    width: max-content;
    max-width: 300px;
    padding: $size-2;

    background-color: $bg-color;
    box-shadow: $bs-4;
    border-radius: $roundness-2 $roundness-2 $roundness-2 0px;

    &::after {
      content: "";
      position: absolute;
      bottom: -$height + 1px;
      left: 0px;

      background-color: $bg-color;
      clip-path: polygon(100% 0, 0 0, 0 100%);
      width: 14px;
      height: $height;
    }
  }
}

.ds-skeleton {
  @keyframes skeleton {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  @mixin ds-animation {
    background: linear-gradient(90deg, #f2f2f2 0px, #dbdbdb 50%, #f2f2f2 100%);
    animation: skeleton 3s ease-in-out infinite;
    background-size: 200% 100%;
    border-radius: $roundness-1;
  }

  display: inline-block;

  &-animation {
    @include ds-animation;
  }

  &-avatar {
    @include ds-animation;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin: auto;
  }

  &-text {
    display: flex;
    flex-direction: column;

    .text-line {
      @include ds-animation;
    }
  }
}

.ds-toast {
  @keyframes appear {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -80px);
    }
  }

  @keyframes disappear {
    0% {
      transform: translate(-50%, -80px);
    }
    100% {
      transform: translate(-50%, 100%);
    }
  }

  &-present {
    transform: translate(-50%, -80px);
  }

  position: fixed;
  left: 50%;
  bottom: 0px;
  border-radius: $roundness-2;
  padding: 16px 40px;
  box-shadow: $bs-4;
  max-width: 500px;

  transform: translate(-50%, 100%);

  &-default {
    background-color: $black-0;
  }

  &-success {
    background-color: $success;
  }

  &-error {
    background-color: $danger;
  }

  &-info {
    background-color: #81d0f0;
  }

  &-warning {
    background-color: #ffc64a;
  }

  &-starting {
    animation: appear 0.5s cubic-bezier(0.08, 0, 0.19, 1) forwards;
  }

  &-stopping {
    animation: disappear 0.8s cubic-bezier(0.21, 0, 1, 0.04) forwards;
  }
}

.ds-toast-v3 {
  @keyframes appear {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -80px);
    }
  }

  @keyframes disappear {
    0% {
      transform: translate(-50%, -80px);
    }
    100% {
      transform: translate(-50%, 100%);
    }
  }

  position: fixed;
  left: 50%;
  bottom: 0px;
  border-radius: $roundness-2;
  padding: 16px 40px;
  box-shadow: $bs-4;

  width: 450px;

  color: $white-1;
  transition: background-color 0.2s ease-in-out;

  &.success {
    background-color: $success;
  }
  &.danger {
    background-color: $danger;
  }
  &.black {
    background-color: $black-0;
  }
  &.info {
    background-color: $link-blue;
  }
  &.warning {
    background-color: $warning;
  }

  &.inactive {
    animation: disappear 0.8s cubic-bezier(0.21, 0, 1, 0.04) forwards;
  }

  &.default {
    transform: translate(-50%, 100%);
    background-color: transparent;
  }

  &.active {
    animation: appear 0.5s cubic-bezier(0.08, 0, 0.19, 1) forwards;
  }
}

.expandable-border {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  box-shadow: none;

  cursor: pointer;

  transition: box-shadow 0.45s ease-in-out;

  &.active {
    box-shadow: $orange-inner-shadow;
  }
}

.expandable-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  aspect-ratio: 1/1;

  transform: translate(-50%, -50%);

  border-radius: inherit;
}

.ds-expandable {
  @keyframes rotate-right {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes rotate-left {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }

  border-radius: inherit;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  transition: width 0.5s cubic-bezier(0.72, 0, 0.34, 1), height 0.5s cubic-bezier(0.72, 0, 0.34, 1),
    left 0.5s cubic-bezier(0.72, 0, 0.34, 1), top 0.5s cubic-bezier(0.72, 0, 0.34, 1);

  &.active {
    &.dominant {
      width: 272% !important;
      height: 272% !important;
    }

    &.submissive {
      left: 50% !important;
      top: 50% !important;

      width: 145% !important;
      height: 145% !important;
    }

    img {
      animation: rotate-right 0.5s cubic-bezier(0.72, 0, 0.34, 1) forwards;
    }
  }

  &.inactive {
    img {
      animation: rotate-left 0.5s cubic-bezier(0.72, 0, 0.34, 1) forwards;
    }
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    background: $primary;
    box-shadow: $icon-inner-shadow;
    @include flex-center();

    img {
      margin-top: 1px;
      margin-left: 1px;
    }
  }
}

.ds-dropzone {
  position: relative;
  min-height: max-content;
  width: 100%;
  height: 100%;
  background-color: $white-7;
  border-radius: $roundness-2;

  overflow: hidden;

  &:hover {
    background-color: $white-2;
  }

  .children {
    border-radius: inherit;
    @include flex-center();
    width: 100%;
    height: 100%;
    border: 2px dashed $white-3;
    box-shadow: 0px 1px 2px 0px #0000001a inset;

    transition: none;
  }

  .file-input {
    border-radius: inherit;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }

  .preview {
    border-radius: inherit;
    width: max-content;
    max-width: 100%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    transition: none;

    img {
      width: max-content;
      max-width: 100%;
    }
  }

  .delete-btn {
    position: absolute;
    right: $size-2;
    top: $size-2;
    border: transparent;
  }

  .drop-or-browse {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.error {
    border: 1px solid $danger;
    box-shadow: none;
    background-color: rgba($danger, 0.05);
  }
}

.ds-separator {
  margin-top: $size-1;
  margin-bottom: $size-1;
  background-color: $white-6;

  &.horizontal {
    width: 100%;
  }

  &.vertical {
    height: 100%;
  }
}

.ds-more-menu {
  position: relative;

  .menu-btn {
    transition: background-color 0.2s ease-in-out;
    border-radius: 100%;

    position: relative;
    cursor: pointer;
    height: 20px;
    box-sizing: content-box;
    padding: 10px 18px;

    display: flex;

    .menu-actions-list {
      position: absolute;
      right: 40%;
      top: 40%;
      width: max-content;
      height: max-content;

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

      z-index: 1000;

      .menu-action {
        min-width: 210px;
        cursor: pointer;
        height: max-content;

        padding: 16px;
        border-radius: $roundness-2;
        display: flex;
        flex-direction: row;

        justify-content: flex-start;
        align-items: center;
        column-gap: 16px;

        &:disabled {
          opacity: 0.5;
        }
        &:hover:not(:disabled) {
          background-color: $white-1;
        }
      }
      &.open {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      background-color: rgba($black-0, 0.05);
    }
  }
}

.ds-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: $black-0;
}

.ds-switch {
  $padding: 4px;
  $height: 40px;

  cursor: pointer;

  position: relative;
  width: 66.66px;
  height: $height;

  border-radius: $height;
  padding: $padding;
  overflow: hidden;

  box-shadow: 0px 1.66667px 0.83333px 0px rgba(0, 0, 0, 0.05) inset;

  transition: background-color 0.4s cubic-bezier(0.84, 0, 0.19, 0.9);

  &.light-dark {
    background-color: $white-7;
    &.checked {
      background-color: $black-0;
    }
  }

  &.classic {
    background-color: $white-7;
    &.checked {
      background-color: $success;
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .switch-circle {
    position: absolute;

    height: calc($height - (2 * $padding));
    width: calc($height - (2 * $padding));
    border-radius: $height;
    background-color: $white-0;
    border: 1px solid rgba($white-0, 0.1);
    transform: initial;

    overflow: hidden;

    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0.8333332538604736px 1.6666665077209473px rgba(0, 0, 0, 0.1),
      0px 3.3333330154418945px 3.3333330154418945px rgba(0, 0, 0, 0.09),
      0px 8.333333015441895px 4.999999523162842px rgba(0, 0, 0, 0.05),
      0px 14.999999046325684px 5.8333330154418945px rgba(0, 0, 0, 0.01),
      0px 23.333332061767578px 6.666666030883789px rgba(0, 0, 0, 0);

    transition: transform 0.5s cubic-bezier(0.84, 0, 0.19, 1);

    &.checked {
      transform: translateX(calc(100% - 1.5 * $padding));
    }

    .switch-images {
      position: absolute;

      display: flex;
      flex-direction: column;

      left: 50%;

      transform: translate(-50%, 0px);
      height: calc((2 * $height) - (4 * $padding) - 1px);
      width: calc($height - (2 * $padding));

      transition: transform 0.5s cubic-bezier(0.84, 0, 0.19, 1);

      &.checked {
        transform: translate(-50%, -50%);
      }

      .img-wrapper {
        height: calc(2 * $height - (2 * $padding));
        width: calc($height - (2 * $padding));
        @include flex-center();

        .img-icon {
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .switch-images {
      display: none;
    }
  }
}

%close-btn {
  cursor: pointer;

  position: absolute;
  right: 0px;
  top: 0px;
  padding: 12px;

  .circle {
    transition: background-color 0.2s ease-in-out;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: rgba($black-1, 0.05);
    @include flex-center();
  }

  &:hover {
    .circle {
      background-color: rgba($black-1, 0.1);
    }
  }

  &:active {
    .circle {
      background-color: rgba($black-1, 0.2);
    }
  }
}

.ds-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 90vh;

  display: flex; //TODO - check later (may introduce bugs)
  max-height: max-content;
  width: max-content;

  border-radius: inherit;

  transform: translate(-50%, -50%);

  .close-btn {
    @extend %close-btn;
  }
}
